import * as types from "./actionType";
import API from "../../utils/endpoints";
import history from "../../history";
import { handleToast } from "../../utils/common";
import Cookies from "js-cookie";

export function fetchingProducts() {
  return {
    type: types.GET_ALL_PRODUCTS,
  };
}

export function fetchingProductsSuccess(payload) {
  return {
    type: types.GET_ALL_PRODUCTS_SUCCESS,
    payload,
  };
}

export function fetchingProductsFailed(payload) {
  return {
    type: types.GET_ALL_PRODUCTS_FAILED,
    payload,
  };
}

export function creatingProducts() {
  return {
    type: types.CREATE_PRODUCTS,
  };
}

export function creatingProductsSuccess(payload) {
  return {
    type: types.CREATE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function creatingProductsFailed(payload) {
  return {
    type: types.CREATE_PRODUCTS_FAILED,
    payload,
  };
}

export function updatingProducts() {
  return {
    type: types.UPDATE_PRODUCTS,
  };
}

export function updatingProductsSuccess(payload) {
  return {
    type: types.UPDATE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function updatingProductsFailed(payload) {
  return {
    type: types.UPDATE_PRODUCTS_FAILED,
    payload,
  };
}

export function deletingProducts() {
  return {
    type: types.DELETE_PRODUCTS,
  };
}

export function deletingProductsSuccess(payload) {
  return {
    type: types.DELETE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function deletingProductsFailed(payload) {
  return {
    type: types.DELETE_PRODUCTS_FAILED,
    payload,
  };
}

export function imageUploadForProducts() {
  return {
    type: types.IMAGE_UPLOAD,
  };
}

export function imageUploadForProductsSuccess(payload) {
  return {
    type: types.IMAGE_UPLOAD_SUCCESS,
    payload,
  };
}

export function imageUploadForProductsFailed(payload) {
  return {
    type: types.IMAGE_UPLOAD_FAILED,
    payload,
  };
}
//****** get all Products   ********//

//export const getAllProducts = () => async (dispatch, getState, api) => {
//  dispatch(fetchingProducts());
//  try {
//    const response = await api.get(API.getAllProducts);
//    if (response.data.code === 200) {
//      dispatch(fetchingProductsSuccess(response.data.res));
//    } else {
//      dispatch(fetchingProductsFailed([]));
//    }
//  } catch (error) {
//    handleToast("something went wrong!! please try again after sometime");
//    dispatch(fetchingProductsFailed([]));
//  }
//};


export const getAllProducts = () => async (dispatch, getState, api) => {
  const cookieeData = Cookies.get("User");
  dispatch(fetchingProducts());
  try {
//    const response = await api.get(API.getAllProducts);
    console.log("before")
    let parsedData = JSON.parse(cookieeData);
    console.log("vendorID: ",parsedData.vendorId)
    console.log("after")

    const response = await api.get(
            `${API.getAllProducts}/vendor/${parsedData.vendorId}`
          );
    if (response.data.code === 200) {
      dispatch(fetchingProductsSuccess(response.data.res));
    } else {
      dispatch(fetchingProductsFailed([]));
    }
  } catch (error) {
    handleToast("something went wrong!! please try again after sometime");
    dispatch(fetchingProductsFailed([]));
  }
};


//****** create Products  ********//

export const createProduct = (payload) => async (dispatch, getState, api) => {
  dispatch(creatingProducts());
  try {
    const response = await api.post(API.createProduct, payload);
    if (response.data.code === 200) {
      let dataForImage = {
        image: payload.imageForUpload,
        productId: response.data.res.id,
      };

      handleToast(response.data.message);
      dispatch(creatingProductsSuccess(response.data.res));
      if (payload.imageForUpload) {
        dispatch(uploadImage(dataForImage));
      } else {
        dispatch(getAllProducts());
        // Check if the current path is '/create-invoice'
        if (history.location.pathname !== '/create-invoice' && history.location.pathname !== '/update-invoice') {
          history.push('/product'); // Only redirect if not on '/create-invoice' page
        }
      }
    } else {
      handleToast(response.data.message);
      dispatch(creatingProductsFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(creatingProductsFailed([]));
  }
};

//****** update Products   ********//

export const updateProduct = (payload) => async (dispatch, getState, api) => {
  dispatch(updatingProducts());

  try {
    const response = await api.put(
      `${API.updateProduct}/${payload.id}`,
      payload
    );
    let dataForImage = {
      image: payload.imageForUpload,
      productId: payload.id,
    };

    if (response.data.code === 200) {
      handleToast(response.data.message);
      dispatch(updatingProductsSuccess(response.data.res));
      if (payload.imageForUpload) {
        dispatch(uploadImage(dataForImage));
      } else {
        dispatch(getAllProducts());
        history.push("/product");
      }
    } else {
      handleToast(response.data.message);
      dispatch(updatingProductsFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(updatingProductsFailed([]));
  }
};

//****** delete Products   ********//

export const deleteProduct = (payload) => async (dispatch, getState, api) => {
  dispatch(deletingProducts());
  try {
    let response = await api.delete(`${API.deleteProduct}/${payload.id}`);
    if (response.data.code === 200) {
      handleToast(response.data.message);
      dispatch(deletingProductsSuccess(response.data.data));
      dispatch(getAllProducts());
    } else {
      handleToast(response.data.message);
      dispatch(deletingProductsFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(deletingProductsFailed([]));
  }
};

//****** delete Products   ********//

export const uploadImage = (payload) => async (dispatch, getState, api) => {
  dispatch(imageUploadForProducts());
  try {
    let formData = new FormData();
    formData.append("productId", payload.productId);
    formData.append("file", payload.image);
    let response = await api.post(API.uploadImage, formData);

    if (response.data.code === 200) {
      dispatch(imageUploadForProductsSuccess(response.data.data));
      dispatch(getAllProducts());
      if (history.location.pathname !== '/create-invoice' && history.location.pathname !== '/update-invoice') {
        history.push('/product'); // Only redirect if not on '/create-invoice' page
      }
    } else {
      handleToast(response.data.message);
      dispatch(imageUploadForProductsFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(imageUploadForProductsFailed([]));
  }
};

//export const downloadImage = (image) => {
//    const imageUrl = image.preview;
//    console.log("imageUrl : ",imageUrl)
//    console.log("1")
//    const imageDownloadLink = document.createElement("imageDownload");
//    console.log("2")
//    imageDownloadLink.href = imageUrl;
//    console.log("3")
//    document.body.appendChild(imageDownloadLink);
//    console.log("4")
//    imageDownloadLink.click();
//    console.log("5")
//  };

export function imageDownloadForProduct() {
  return {
    type: types.IMAGE_DOWNLOAD,
  };
}

export function imageDownloadForProductSuccess(payload) {
  return {
    type: types.IMAGE_DOWNLOAD_SUCCESS,
    payload,
  };
}

export function imageDownloadForProductFailed(payload) {
  return {
    type: types.IMAGE_DOWNLOAD_FAILED,
    payload,
  };
}

//export const downloadImage = (imageUrl) => {
//  dispatch(imageDownloadForProduct());
//  // Check if the imageUrl is provided
//  if (!imageUrl) {
//    console.error("Image URL is required to download the image.");
//    return;
//  }
//  else {
//    console.log("imageUrl : ",imageUrl)
//  }
//
//  // Create a temporary link element
//  const link = document.createElement('abc');
//
//  // Extract the image name from the URL or use a default name
//  const fileName = imageUrl.split('/').pop() || 'downloaded-image';
//
//  // Set the download attribute with the extracted filename
//  link.download = fileName;
//
//  // Set the href to the image URL
//  link.href = imageUrl;
//
//  // Programmatically click the link to trigger the download
//  document.body.appendChild(link);
//  link.click();
//
//  // Clean up by removing the temporary link
//  document.body.removeChild(link);
//};
